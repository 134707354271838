import React, { useState } from 'react';
import { Modal, ModalBody } from 'baseui/modal';
import { Text } from '../../../globalStyles';
import { FinalButton } from '../../../Components/FinalButton';
import { Notification } from '../../../Components/Notification';

export const OrgCouplingConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  isCoupled,
}: {
  isOpen: boolean;
  isCoupled: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
}) => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleConfirm = async () => {
    try {
      setError(null);
      setIsLoading(true);
      await onConfirm();
    } catch (err) {
      setError('Failed to update entitlement configuration. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const headerId = 'entitlement-modal-header';

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      autoFocus
      role="dialog"
      overrides={{
        Dialog: {
          props: {
            'aria-labelledby': headerId,
          },
        },
      }}
    >
      <ModalBody className="pa4">
        <Text.h2 className="mb3" id={headerId}>
          {isCoupled
            ? 'Customize child org entitlements by detaching from parent org configuration?'
            : 'Update org entitlements to inherit from parent organization?'}
        </Text.h2>
        <Text.body className="mb4">
          This will overwrite the current entitlement configuration for this child org.
        </Text.body>
        <div className="flex flex-column">
          {error && <Notification kind="negative">{error}</Notification>}
          <FinalButton loading={isLoading} onClick={handleConfirm} kind="danger" className="mb2">
            Yes, {isCoupled ? 'customize' : 'inherit'} entitlements
          </FinalButton>
          <FinalButton onClick={onClose} kind="minimal_gray">
            Cancel
          </FinalButton>
        </div>
      </ModalBody>
    </Modal>
  );
};
