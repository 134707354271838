import * as Styles from './styles';
import { Text } from '../../globalStyles';
import { UniversityLogo } from '../../Components/Organization/UniversityLogo';
import React, { useRef, useState } from 'react';
import { hasEntitlement } from '../../utils';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Entitlement, useOrganizationForEnrollPageQuery } from '../../graphQL';
import { useCurrentProvider } from '../../Components/Permissions/AuthWrapper';
import { UnexpectedError } from '../Shared';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { EnrollOptionsComponent } from './EnrollSelection/EnrollOptionsComponent';
import { DefaultEnrollScreen } from './EnrollSelection/DefaultEnrollScreen';

export function EnrollSelection() {
  const params = useParams<{ organizationId: string }>();
  const { appView } = useCurrentProvider();
  const organizationId = Number(params.organizationId);
  const oneMonthOutRef = useRef(moment().add(1, 'month').format());
  const [showSelectionScreen, setShowSelectionScreen] = useState(true);
  const {
    data: orgData,
    loading,
    error: loadError,
  } = useOrganizationForEnrollPageQuery({
    variables: {
      id: organizationId,
      includeProviders: appView !== 'referral',
      // use a ref to prevent this query from re-running when the time changes (i.e. constantly)
      nextTimeSlotEnd: oneMonthOutRef.current,
    },
  });
  if (loading) return <LoadingPage />;
  if (loadError || !orgData) return <UnexpectedError />;
  const { organization } = orgData;
  const allowMultipleReferralTypes = hasEntitlement(
    organization,
    Entitlement.AllowMultipleReferralTypes
  );

  // if WCC entitlement is on, we allow for different referral types- show selection screen
  if (allowMultipleReferralTypes && showSelectionScreen) {
    return (
      <Styles.Background>
        <Styles.EnrollSelectionWrapper>
          <Text.label>Enroll New Student</Text.label>
          <UniversityLogo />
          <Text.h1>Select Referral Type</Text.h1>
          <EnrollOptionsComponent
            organization={organization}
            setShowSelectionScreen={setShowSelectionScreen}
          />
        </Styles.EnrollSelectionWrapper>
      </Styles.Background>
    );
  }

  return <DefaultEnrollScreen organization={organization} />;
}
