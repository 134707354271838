import React from 'react';
import { Tabs } from '../../Components/Tabs';
import { useQueryParams } from '../../Hooks';
import { Coupons } from './Coupons';

export function ConfigPage() {
  const [params, setParams] = useQueryParams();

  return (
    <div className="bg-white">
      <h1 className="pa4">Mantra Configuration</h1>
      <Tabs
        initialTab={(params.tab ?? 'coupons') as any}
        config={{ tabpanel: ({ children }) => <div className="pa4">{children}</div> }}
        onChange={tab => setParams({ tab })}
        tabs={[{ title: 'Coupons', key: 'coupons', render: Coupons }]}
      />
    </div>
  );
}
