import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Automatically redirect user to connectnow version of crisis reports page
 */
export const useRedirectFromOnDemand = (): void => {
  const history = useHistory();
  const locationIsOdes = history.location.pathname.includes('odes-reports');
  useEffect(() => {
    if (locationIsOdes) {
      history.replace('/connectnow-reports');
    }
  }, [history, locationIsOdes]);
};
